import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * Formats a Unix timestamp into a human-readable time format.
 * @param {number} time - Unix timestamp.
 * @param {string} [dateFormat='HH:mm'] - Desired format of the time.
 * @returns {string} Formatted time string.
 */
export const messageStamp = (time, dateFormat = 'HH:mm') => {
  const unixTime = fromUnixTime(time);
  return format(unixTime, dateFormat, { locale: ptBR });
};

/**
 * Provides a formatted timestamp, adjusting the format based on the current year.
 * @param {number} time - Unix timestamp.
 * @param {string} [dateFormat='d MMM yyyy'] - Desired date format.
 * @returns {string} Formatted date string.
 */
export const messageTimestamp = (time, dateFormat = 'd MMM yyyy') => {
  const messageTime = fromUnixTime(time);
  const now = new Date();
  const messageDate = format(messageTime, dateFormat, { locale: ptBR });
  if (!isSameYear(messageTime, now)) {
    return format(messageTime, 'd MMM yyyy, HH:mm', { locale: ptBR });
  }
  return messageDate;
};

/**
 * Converts a Unix timestamp to a relative time string (e.g., há 3 horas).
 * @param {number} time - Unix timestamp.
 * @returns {string} Relative time string.
 */
export const dynamicTime = time => {
  const unixTime = fromUnixTime(time);
  return formatDistanceToNow(unixTime, { addSuffix: true, locale: ptBR });
};

/**
 * Formats a Unix timestamp into a specified date format.
 * @param {number} time - Unix timestamp.
 * @param {string} [dateFormat='d MMM yyyy'] - Desired date format.
 * @returns {string} Formatted date string.
 */
export const dateFormat = (time, df = 'd MMM yyyy') => {
  const unixTime = fromUnixTime(time);
  return format(unixTime, df, { locale: ptBR });
};

/**
 * Converts a detailed time description into a shorter format, optionally appending 'há'.
 * @param {string} time - Detailed time description (e.g., 'há um minuto').
 * @param {boolean} [withAgo=false] - Whether to append 'há' to the result.
 * @returns {string} Shortened time description.
 */
export const shortTimestamp = (time, withAgo = false) => {
  const prefix = withAgo ? 'há' : '';
  const timeMappings = {
    'há menos de um minuto': 'agora',
    'há um minuto': `${prefix} 1 min`,
    'há uma hora': `${prefix} 1 h`,
    'há um dia': `${prefix} 1 d`,
    'há um mês': `${prefix} 1 mês`,
    'há um ano': `${prefix} 1 a`,
  };

  if (timeMappings[time]) {
    return timeMappings[time].trim();
  }
  const convertToShortTime = time
    .replace(/aproximadamente|mais de|quase|atrás|cerca de/g, '')
    .replace('minutos', `min`)
    .replace('minuto', `min`)
    .replace('horas', `h`)
    .replace('hora', `h`)
    .replace('dias', `d`)
    .replace('dia', `d`)
    .replace('meses', `mês`)
    .replace('mês', `mês`)
    .replace('anos', `a`)
    .replace('ano', `a`);

  return withAgo
    ? convertToShortTime.trim()
    : convertToShortTime.replace(/há/g, '').trim();
};
